import React from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import {storage} from "../../utils/storage";

export const SsoView = () => {
    const {setMpid} = React.useContext(QuestionaryContext);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [origin, setOrigin] = React.useState('');
    const [token, setToken] = React.useState('');
    const [targetUser, setTargetUser] = React.useState('');
    const [source, setSource] = React.useState('');
    const [permission, setPermission] = React.useState('All');

    function setDataFromIClinic(event: any){
        setOrigin(event.origin);
        try {
            console.log(event.data)
            const json = JSON.parse(event.data);
            console.log(json)
            setToken(json.token);
            setTargetUser(json.mpid);
            setSource(json.source);
            setPermission(json.permission??'All');

        } catch (e: any) {
                    console.log(e);
        }
    }

    React.useEffect(()=>{
        //clean previous session
        storage.clearAll();
        window.addEventListener('message', setDataFromIClinic);
        return () => { window.removeEventListener('message', setDataFromIClinic); }
    },[])

    React.useEffect(()=>{
        if(token && targetUser){
            storage.setItem("AccessToken", token);
            storage.setItem('mpid', targetUser);
            storage.setItem('source', source);
            storage.setItem('permission', permission);
            setMpid(targetUser);
            setLoading(false)
            setTimeout(()=>{
                window.removeEventListener('message', setDataFromIClinic);
                navigate('/menu');
            }, 1000);
        }
    },[token, targetUser]);


    return (
        <Box component="section"
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             justifyContent={'center'}
             height={'100%'}>
            <CircularProgress/>
            {/*<Typography>{loading?'Processing':'Welcome'}</Typography>*/}
            {/*{loading?null:<Typography>{`Coming from ${origin}`}</Typography>}*/}
            {/*{loading?<CircularProgress/>:(<Typography>Redirecting in 3s...</Typography>)}*/}
        </Box>
    )
}