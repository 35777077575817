import React from 'react';
import {QuestionaryContext} from "../../context/QuestionaryContext";

import axiosHttp from "../../utils/axios";
import {Box, CircularProgress, Divider, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomConfirmDialog from "../questionary/components/QuestionHeader/CustomConfirmDialog";
import CloseIcon from "@mui/icons-material/Close";
import {FindHelpIframe} from "./findHelpIframe";
import {config} from "../../config/config";

export const FindHelpHome = () => {
    const {mpid, patientInfo, collapsed, histories} = React.useContext(QuestionaryContext);
    const [key, setKey] = React.useState<any>(null);
    const [jwk, setJwk] = React.useState<any>(null);
    const [url, setUrl] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [onClickClose, setOnClickClose] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const generateCrypt = async() => {
            window.crypto.subtle.generateKey(
                {
                    name: "AES-GCM",
                    length: 256, //can be  128, 192, or 256
                },
                true, //whether the key is extractable (i.e. can be used in exportKey)
                ["encrypt", "decrypt"] //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
            )
                .then(function(key){
                    //returns a keypair object
                    setKey(key)
                    window.crypto.subtle.exportKey(
                        "jwk", //can be "jwk" or "raw"
                        key //extractable must be true
                    )
                        .then(function(keydata){
                            //returns the exported key data
                            setJwk(keydata);
                        })
                        .catch(function(err){
                            console.error(err);
                        });
                })
                .catch(function(err){
                    console.error(err);
                });
        };
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/CommunityResource/Findhelp', {
                    "mpid": mpid,
                    "action": "GetSeekerProfileTokenRedirectUrl"
                });
                if(response?.data?.isSuccess === true){
                    setUrl(response?.data?.data);
                }
                else{
                    setUrl("404");
                }
                setLoading(false);
            } catch (e) {
                console.log(e)
            }

        }
        if (mpid) {
           getData();
            generateCrypt();
        }
    }, [mpid])

    if (patientInfo && patientInfo?.isFindHelpEnable === false) {
        navigate('/home')
    }
    return loading ? <Box component="section"
                          bgcolor={'#FFFFFF'}
                          display="flex"
                          alignItems="center"
                          flexDirection={'column'}
                          justifyContent={'center'}
                          height={'100%'}>
            <CircularProgress/>
        </Box> :
        <Box display="flex"
             flexDirection={'column'}
             position={'relative'}
             bgcolor={'#FFFFFF'}
             justifyContent={'center'}
             height={'100%'}
             sx={{borderRadius: '8px', maxHeight: '100%'}}>
            <CustomConfirmDialog
                title={'Close Find Help'}
                content={'Are you sure you want to close without saving?\nAny unsaved changes will be lost.'}
                open={onClickClose}
                onClose={() => setOnClickClose(false)}
                onConfirm={() => {
                    try {
                        let iframe = document.getElementById("findHelpIframe") as HTMLIFrameElement;
                        if (iframe) {
                            let temp = {
                                action: 'unload'
                            };
                            iframe?.contentWindow?.postMessage(JSON.stringify(temp), "*");
                        }
                    }
                    catch(e) {
                        console.log(e);
                    }
                    finally {
                        setTimeout(()=>{
                            navigate('/menu');
                        },500);
                    }
                }}
            />
            <Box>
                <Box height={40} className={collapsed?"collapseBox":"notCollapseBox"}>
                    <Typography variant="h3">Community Resources - Findhelp</Typography>
                </Box>
                <Divider/>
            </Box>
            <CloseIcon className='closeButton' onClick={() => {
                setOnClickClose(!onClickClose)
            }}/>
            {(jwk)?(url && url === "404"?
                            <Box width="100%" height="100%">
                                <Typography>Cannot get url from Findhelp</Typography>
                                <Typography>Please try again later or contact support {config.csEmail}</Typography>
                            </Box>:
                        <FindHelpIframe url={url} mpid={mpid} key={key} jwk={jwk} histories={histories}/>
                )
                :
                <Box padding={'16px 16px 24px 16px'} display={'flex'} justifyContent={'center'}><CircularProgress/></Box>
            }
        </Box>


}