export const SpecialHandling = [
    {
        QuestionaryCode: "1115CMSAHCHRSNDec10SOMOSSCN",
        rules: [
            {
                QuestionCode: '1115CMSAHCHRSNDec10SOMOSSCN-S2-Q2',
                Exist: '1115CMSAHCHRSNDec10SOMOSSCN-S2-Q1-A1'
            }
        ]
    }
]