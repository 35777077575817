import React, {useRef} from 'react';
import axiosHttp from "../../utils/axios";

interface Props {
    url: string,
    mpid: string,
    key: any;
    jwk: any;
    histories: any;
}

const bufferToBase64 = function (buffer: any) {
    let decoder = new TextDecoder();
    return decoder.decode(buffer);
};

const parseUInt8Array = function(uInt8Array: Uint8Array, length: number){
    let result = new Uint8Array(length);
    for(let i = 0; i < length; i++){
        result[i] = uInt8Array[i];
    }
    return result;
}

const bytes = function (text: any) {
    return new TextEncoder().encode(text);
}

async function encryptData(data: any, key: any){
    let result = {};
    let encodeData = bytes(data);
    let iv = window.crypto.getRandomValues(new Uint8Array(12));
    await window.crypto.subtle.importKey(
        "jwk", //can be "jwk" or "raw"
        key,
        {   //this is the algorithm options
            name: "AES-GCM",
        },
        false, //whether the key is extractable (i.e. can be used in exportKey)
        ["encrypt", "decrypt"] //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
    )
        .then(async function(key){
            //returns the symmetric key
            await window.crypto.subtle.encrypt(
                {
                    name: "AES-GCM",

                    //Don't re-use initialization vectors!
                    //Always generate a new iv every time your encrypt!
                    //Recommended to use 12 bytes length
                    iv: iv,
                    //Additional authentication data (optional)
                    // additionalData: ArrayBuffer,

                    //Tag length (optional)
                    tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
                },
                key, //from generateKey or importKey above
                encodeData //ArrayBuffer of data you want to encrypt
            )
                .then(function(encrypted){
                    //returns an ArrayBuffer containing the encrypted data
                    let dataArray = new Uint8Array(encrypted);
                    result = {
                        data: dataArray,
                        length: dataArray.length,
                        ivRaw: iv,
                    };
                })
                .catch(function(err){
                    console.error(err);
                });
        })
        .catch(function(err){
            console.error(err);
        });
    return result;

}

export const FindHelpIframe = (props: Props) => {
    const intervalRef = useRef<any>(null);
    const [responseId, setResponseId] = React.useState('');

    function getRPAFailReason(q: number, a: number){
        let result = "";
        if(q>0){
            result += q + " question(s) failed. ";
        }
        if(a>0){
            result += a + " answer(s) failed. ";
        }
        return result;
    }

    async function reportRpaExecutionResult(info: any, source: string){
        if(responseId) {
            let q = info?.ErrorQuestion?.length || 0;
            let a = info?.ErrorAnswer?.length || 0;
            let data = {
                action: "reportRpaExecutionResult",
                responseSessionId: responseId,
                mpid: props.mpid,
                rpaExecutionResult: {
                    rpaModuleCode: "SOMOS_Findhelp",
                    isExecutionSuccess: (q + a) > 0 ? "Partial" : "True",
                    rpaModuleVersion: "1.0",
                    failReason: getRPAFailReason(q, a),
                    extraInfo: JSON.stringify(info),
                    hostWebUrl: source
                }
            }
            try {
                const response = await axiosHttp.post('/RPA', data);
            } catch (e) {
                console.log(e)
            }
        }
    }

    async function reportFormSubmissionResult(info: string, source: string){
        if(responseId) {
            try {
                const response = await axiosHttp.post('/RPA', {
                    action: "reportFormSubmissionResult",
                    responseSessionId: responseId,
                    mpid: props.mpid,
                    rpaFormSubmissionResult: {
                        "rpaModuleCode": "SOMOS_Findhelp",
                        "isSubmissionSuccess": info?"False":"True",
                        "rpaModuleVersion": "1.0",
                        "failReason": JSON.stringify(info),
                        "extraInfo": JSON.stringify(info),
                        "hostWebUrl": source
                    }
                });
            } catch (e) {
                console.log(e)
            }
        }
    }

    React.useEffect(() => {
        if(responseId){
            window.addEventListener('message', readExtensionMessage);
        }
    },[responseId])

    async function getAndSendSessionAnswer(QuestionaryCode: string) {
        let l = props.histories.filter((q: any) => q?.Questionary?.QuestionaryCode === QuestionaryCode);
        if(l.length === 0) return {};
        let tempString = l[0].ResponseSessionId;
        window.removeEventListener('message', readExtensionMessage);
        setResponseId(tempString);

        try {
            const response = await axiosHttp.post('/Questionary/Participant', {
                "mpid": props.mpid,
                "responseSessionId": l[0].ResponseSessionId,
                "action": "SessionAnswers",
            })
            if(response?.data?.data?.questionaryDeepNested){
                response.data.data.questionaryDeepNested.UpdateDateTime = l[0]?.UpdateDateTime;
            }

            let iframe = document.getElementById("findHelpIframe") as HTMLIFrameElement;
            if (iframe) {
                let data = {
                    action: 'sessionAnswer',
                    data: response?.data?.data,
                    ResponseScoreJson: l[0]?.ResponseScoreJson??""
                };
                let encryptedData = await encryptData(JSON.stringify(data), props.jwk);
                iframe?.contentWindow?.postMessage(JSON.stringify(encryptedData), "*");

            }
        } catch (error) {
            console.log(error)
        }
    }
    function clear() {
        clearInterval(intervalRef.current);
    }

    function readExtensionMessage(event: any){
        //store the data in local storage
        let data = JSON.parse(event.data);
        if(data?.action) {
            switch (data.action) {
                case 'getKey':
                    let iframe = document.getElementById("findHelpIframe") as HTMLIFrameElement;
                    let temp = {
                        action: 'masterData',
                        data: props.jwk
                    };
                    iframe?.contentWindow?.postMessage(JSON.stringify(temp), "*");
                    break;
                case 'sessionAnswer':
                    // send the data back to the iframe
                    let questionaryCode = data.QuestionaryCode;
                    getAndSendSessionAnswer(questionaryCode).then();
                    break;
                case 'reportRpa':
                    reportRpaExecutionResult(data.info, event.origin).then();
                    break;
                case 'reportFormSubmit':
                    reportFormSubmissionResult(data.info, event.origin).then();
                    break;
            }
        }
    }

    React.useEffect(() => {
        if(props.jwk) {
            window.addEventListener('message', readExtensionMessage);
        }
    }, [props.jwk])
    return (
        <iframe id="findHelpIframe" src={props.url} width="100%" height="100%" style={{border:0}}></iframe>)
}