declare global {
    interface Window {
        chrome: any; // whatever type you want to give. (any,number,float etc)
        opr: any;
    }
}

export function getWebBrowser(){
    // please note,
// that IE11 now returns undefined again for window.chrome
// and new Opera 30 outputs true for window.chrome
// but needs to check if window.opr is not undefined
// and new IE Edge outputs to true now for window.chrome
// and if not iOS Chrome check
// so use the below updated condition
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isFirefox = winNav.userAgent.indexOf("Firefox") > -1;
    var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    var isGoogleChrome = (typeof winNav.userAgentData !== "undefined") ? winNav.userAgentData.brands[0].brand === "Google Chrome" :  vendorName === "Google Inc.";

    if (isIOSChrome) {
        // is Google Chrome on IOS
    } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false &&
        isGoogleChrome
    ) {
        return "Google Chrome";
        // is Google Chrome
    } else if(isIEedge) {
       return "Edge";
    }
    return "";
}